.header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    text-align: center; 
}

.header {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center; 
    margin-top: 8rem;
}

.header-text {
    font-size: 2.5rem;
    font-family: poppins;
    font-weight: 600;
    margin-bottom: 0.5rem;
    max-width: 90vw;
    color: whitesmoke;
}

.header-about {
    text-align: center; 
    width: 100%;
    max-width: 90vw;
    font-size: 1.25rem;
    font-family: poppins;
    font-weight: 200;
    color: whitesmoke;
    margin-bottom: 3rem;
}

.image-container {
    position: relative; 
    display: inline-block;
}

img {
    display: block; 
    width: 100vw;
    height: 30rem; 
    border-top-left-radius: 19rem;
    border-top-right-radius: 19rem;
    object-fit:cover; 
    margin-top: 5rem;
}

.image-container::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(18, 35, 55, 0) 78%, rgba(18, 35, 55, 1) 100%);
    border-top-left-radius: 19rem;
    border-top-right-radius: 19rem; 
}

.Seraphina {
    color: #E4967B;
}

.contact-btn {
    background-color: white;
    color: black;
    width: 14rem;
    height: 4rem;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    border: none;
    border-radius: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2; 
    transition: 300ms;
    position: relative;
    gap: 1rem;
  }
  
  .contact-icon {
    height: 1.7rem;
    width: 1.7rem;
    margin:0;
  }
  
/* ipad */
@media (min-width: 768px) and (max-width: 1024px){
    .header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        text-align: center; 
    }
    
    .header {
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center; 
        margin-top: 6rem;
    }
    
    .header-about {
        text-align: center;
        max-width: 80vw;
        font-size: 1.5rem;
    }
    
    img {
        width:80vw;
        height: 40rem; 
        margin-top: 5rem;
        border-top-left-radius: 25rem;
        border-top-right-radius: 25rem; 
    }
    
    .image-container::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(18, 35, 55, 0) 60%, rgba(18, 35, 55, 1) 100%);
        border-top-left-radius: 25rem;
        border-top-right-radius: 25rem; 
    }

    .header-text {
        font-size: 3.5rem;
    }
    
    .contact-btn {
        margin-top: .25rem;
        width: 16rem;
        height: 5rem;
        font-size: 1.75rem;
        font-weight: 600;
    }
}

/* laptop */
@media (min-width: 1028px){
    .header-container {
        flex-direction: row;
        align-items: center; 
        text-align: start;
    }
    
    .header {
        margin-right: 5vw;
        justify-content: start;
        align-items: start; 
    }
    
    .header-about {
        text-align: left;
        max-width: 40vw;
        font-size: 1.25rem;
    }
    
    img {
        width: calc(34rem - 6rem);
        height: 41rem; 
        margin-top: 6rem;
    }
    
    .image-container::after {
        background: none;
    }

    .header-text {
        font-size: 3.5rem;
    }
    
    .contact-btn {
        margin-top: 0.5rem;
    }
}

/* desktop */

@media(min-width: 1400px){ 
    img {
        width: calc(45rem - 6rem);
        height: 55rem; 
    }
}

