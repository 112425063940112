.navbar-container {
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100vw;
}

.icon{
    transition: opacity 0.3s ease, transform 0.3s ease; 
    opacity: 1;
    z-index: 999;
}

#burger-menu {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    height: 3rem;
    width: 3rem;
    margin: 0;
    z-index: 1000;
    position: absolute;
    top: 1rem;
    right: .5rem;
    transform: rotate(0deg);
    opacity: 1;
}

#close-icon {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0;
    z-index: 1000;
    position: absolute;
    top: 1rem;
    right: .5rem;
    transform: rotate(90deg);
    opacity: 1;
}

.navbar {
    background-color: rgb(18, 35, 55);
    position: absolute;
    top: 5rem;
    max-width: 100vw;
    z-index: 1;
}

.menu {
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.menuItem {
    color: whitesmoke;
    font-family: poppins;
    font-size: 1rem;
    border: whitesmoke solid 1px;
    padding: 1rem;
    display: inline-block;
}

.active{
    color: #E4967B;
}
/* ipad */
@media (min-width: 768px) and (max-width: 1024px) {
    #burger-menu {
        height: 4rem;
        width: 4rem;
    }
    
    #close-icon {
        height: 3rem;
        width: 3rem;
    }

    .navbar {
        display: flex;
        justify-content: center;
        margin: 0;
    }

    .menuItem {
        font-size: 2rem;
        padding: 1.5rem;
    }
}

/* laptop */
@media (min-width: 1024px) {
    .icon {
        display: none;
    }

    .navbar {
        display: block;
        top:0rem;
        background-color: transparent;
        margin: 1rem;
        height: 3rem;
    }

    .menuItem {
        font-size: 1.5rem;
        font-family: poppins;
        color: whitesmoke;
        margin: 1rem;
        padding-bottom: 0.25rem;
        position: relative;
        border: none;
    }

    .menu {
        justify-content: center;
        flex-direction: row;
    }

    .slider {
        position: absolute;
        display: block;
        left: 0;
        top: 100%;
        margin: 0 auto;
        height: 2px;
        background-color: #E4967B;
        width: 0%;
        transition: color 300ms;
    }

    .menuItem:hover>.slider {
        width: 100%;
        transition: width 300ms;
    }

    .menuItem:hover {
        color: #E4967B;
    }

    /* .active {
        color: #E4967B;
    } */

    .active .slider {
        width: 100%;
    }
}