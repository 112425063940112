.blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 4rem;
}

.block-item {
    background-color: rgb(255, 255, 255, 0.1);
    color: whitesmoke;
    font-family: poppins;
    margin: 1rem;
    width: 100%;
    height:20rem;
    border-radius: 5px;
    padding: 2rem;
    position: relative;
 
}

.title-portfolio {
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
}

.description {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 300;
    color: whitesmoke;
}

/* ipad */
@media (min-width: 768px) and (max-width: 1024px) {
    .blocks {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 6rem
    }

    .block-item {
        background-color: rgb(255, 255, 255, 0.1);
        color: whitesmoke;
        font-family: poppins;
        margin: 1.5rem;
        width: 100%;
        height: 30rem;
        border-radius: 5px;
        padding: 2rem;
    }

    .title-portfolio {
        font-size: 3rem;
        text-align: center;
        font-weight: 600;
        padding: 1rem;
    }

    .description {
        text-align: center;
        font-size: 2rem;
        font-weight: 300;
        color: whitesmoke;
    }
}

/* laptop */
@media (min-width: 1028px) {
    .blocks {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 4rem;
        margin: 0 5rem;
    }

    .block-item {
        background-color: rgb(255, 255, 255, 0.1);
        color: whitesmoke;
        font-family: poppins;
        margin: 1rem;
        width: 100%;
        height: 18rem;
        border-radius: 5px;
        padding: 2rem;
    }

    .title-portfolio {
        font-size: 2rem;
        text-align: center;
        font-weight: 600;
        padding: 1rem;
    }

    .description {
        text-align: center;
        font-size: 1.25rem;
        font-weight: 300;
        color: whitesmoke;
    }
}

@media(min-width: 1550px){
    .block-item {
        height: 30rem;
        margin-bottom: 5rem;
    }
}