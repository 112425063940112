.container-portfolio-title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    padding-bottom: 4rem;
}

.horizontal-scrolling-items-top,
.horizontal-scrolling-items-bottom {
    justify-content: center;
    position: absolute;
    margin-right: 0;
    font-family: monoton;
    font-size: 2.6rem;
}

.horizontal-scrolling-items__item-top {
    font-weight: 400;
    margin-top: 0.8rem;
    color: #CD3C2C;
}

.horizontal-scrolling-items__item-bottom {
    font-weight: 400;
    color: #E4967B;
}

.horizontal-scrolling-items__item-top:nth-child(n+2),
.horizontal-scrolling-items__item-bottom:nth-child(n+2) {
    display: none;
}

/* ipad */
@media (min-width: 768px) and (max-width: 1024px) {
    .horizontal-scrolling-items-top,
    .horizontal-scrolling-items-bottom {
        font-size: 5rem;
        margin-top: 16rem;
    }
}
/* laptop */
@media (min-width: 1024px) {
    @keyframes infiniteScroll {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-100%);
        }
    }

    .container-portfolio-title {
        width: 100%;
        overflow: hidden;
        margin: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 14rem;
        justify-content: normal;
        align-items: normal;
    }

    .horizontal-scrolling-items-top,
    .horizontal-scrolling-items-bottom {
        display: flex;
        font-size: 2.9rem;
        width: 200%;
        position: absolute;
        margin-top: 6rem;
        animation: infiniteScroll 35s linear infinite;
        align-items: center;
    }

    .horizontal-scrolling-items__item-top,
    .horizontal-scrolling-items__item-bottom {
        white-space: nowrap;
        font-family: monoton;
        font-weight: 400;
        flex-shrink: 0;
    }
    
    .horizontal-scrolling-items__item-top {
        font-size: 6rem;
        margin-top: 1.3rem;
        margin-left: 1rem;
    }

    .horizontal-scrolling-items__item-bottom {
        font-size: 6rem;
    }

    .horizontal-scrolling-items__item-top:nth-child(n+2),
    .horizontal-scrolling-items__item-bottom:nth-child(n+2) {
        display: contents;
    }
}

/* desktop */

@media(min-width: 1400px){

    .container-portfolio-title {
        height: 17rem;
    }

    .horizontal-scrolling-items-top,
    .horizontal-scrolling-items-bottom {
        margin-top: 5rem;
    }

    .horizontal-scrolling-items__item-top {
        font-size: 9rem;
    }

    .horizontal-scrolling-items__item-bottom {
        font-size: 9rem;
    }
}